import {
    Table,
    TableHead,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell,
} from "@tremor/react";
import { TextInput } from "@tremor/react";
import {
    setModalOpen,
} from '../campaigns/campaignsSlice';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ModalDeleteUser from "../campaigns/modals/ModalDeleteUser";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { 
    fetchUsers, 
    selectUsersList, 
    setSorting, 
    setUserFilter, 
    selectUserFilter 
} from "./UsersSlice";

const UsersTable = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const usersList = useAppSelector(selectUsersList);
    const userFilter = useAppSelector(selectUserFilter);
    const _url = new URL(window.location.href);
    const _user = _url.searchParams.get('user');
    const currentUserEmail = _user || "";
    //@ts-ignore
    useEffect(() => {
        dispatch(fetchUsers());
        dispatch(setUserFilter(`${currentUserEmail}`));
    }, []);

    const handleUserOnClick = (id: string) => {
        navigate("/admin/utenze/modifica-utente/" + id);
    }

    const handleSrc = (e: React.FormEvent<HTMLInputElement>) => {
        dispatch(setUserFilter(e.currentTarget.value.toLowerCase()));
    }

    return (
        <div className="pzo__table-route campaigns__table users__table bradius-bshadow bg-transparent-white">
            <div className="pzo__table-filter">
                <TextInput placeholder="Filtra utenti..."
                onChange={handleSrc} value={userFilter} />
            </div>
            <Table marginTop="mt-0">
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>
                            <span
                                className='table__head'
                                onClick={() => dispatch(setSorting('NAME'))}>Nome</span>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <span
                                className='table__head'
                                onClick={() => dispatch(setSorting('EMAIL'))}>Email</span>
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <span
                                className='table__head'
                                onClick={() => dispatch(setSorting('ROLE'))}>Ruolo</span>
                        </TableHeaderCell>
                        <TableHeaderCell>
                        </TableHeaderCell>
                        <TableHeaderCell>
                        </TableHeaderCell>

                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        usersList.map(user => {
                            return (
                                <TableRow key={user.id}>
                                    <TableCell>
                                        <span
                                            className="min-w">{user.custom_data.name}</span>
                                    </TableCell>

                                    <TableCell>
                                        <span className="min-w">{user.data.email}</span>
                                    </TableCell>

                                    <TableCell>
                                        <span className="min-w">{user.custom_data.role === "agent" ? "Agente" : "Admin"}</span>
                                    </TableCell>

                                    <TableCell>
                                        <span className="edit-user"
                                            onClick={() => handleUserOnClick(user.id.toString())}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#013DEE">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                            Modifica
                                        </span>
                                    </TableCell>

                                    <TableCell>
                                        <span className="delete-user"
                                            onClick={() => dispatch(setModalOpen(
                                                <ModalDeleteUser
                                                    userName={user.custom_data.name}
                                                    userEmail={user.data.email}
                                                />
                                            ))}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#FF632F">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                            Elimina
                                        </span>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }

                </TableBody>
            </Table>
        </div >
    )
}

export default UsersTable