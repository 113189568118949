import { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';
import {
  SelectBox,
  SelectBoxItem,
} from "@tremor/react";
import { toast } from 'react-hot-toast';
import { validateEmails, validateSubject } from '../../../utils/utils';
import Alert from '../alert/Alert';
import { useAppDispatch } from '../../../app/hooks';
import { resetSelectedForSend, setModalOpen, setSelectionEnabled } from '../campaignsSlice';
import { IDeviceEvent } from '../PaparazzoTypes';
import { screenshotBasePath } from '../../../commons/config/settings';

const ModalForm = (props: any) => {

  const form = useRef<HTMLFormElement>(null);
  const dispatch = useAppDispatch();

  // Input values store
  const [to, setTo] = useState('');
  const [sreenshotType, setSreenshotType] = useState(1);
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('');

  // Input errors store
  const [toError, setToError] = useState('');
  const [subjectError, setSubjectError] = useState('')

  const screenshotsUrls = props.screenshotsUrls?.map((event: IDeviceEvent) => {
    let screenshot: string | undefined = '';
    if (sreenshotType === 1) {
      screenshot = event.urlClippedBase
    } else if (sreenshotType === 2) {
      screenshot = event.urlClippedUI
    } else {
      screenshot = event.urlFullPage
    }
    return screenshotBasePath + screenshot
  })

  const urls: string[] = [];
  screenshotsUrls.map((url: string) => url !== "" && urls.push(url));

  const sendEmail = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    emailjs.sendForm('paparazzo_gmail', 'default_template', form.current, 'GongzXHgxVw6f0brA', {
    })
      .then((result) => {
        dispatch(setModalOpen());
        dispatch(setSelectionEnabled());
        dispatch(resetSelectedForSend());
        toast.success("Email inviata con successo!");

      }, (error) => {
        toast.error("Spiacenti, c'è stato un errore nell'invio.");
      });
  };

  return (
    <div className="modal-form">

      <div className="modal-form__head">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#6B6CF9">
          <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
        </svg>
        <span className="h3">Invia tramite email</span>
      </div>

      <form ref={form} onSubmit={sendEmail}>

        <div className="modal-form__inputs-row">
          <span>A:</span>
          <input
            type="email"
            name="to"
            value={to}
            multiple
            onChange={(e) => setTo(e.target.value)}
            onBlur={() => { setToError(validateEmails(to)) }}
          />

          {toError && <Alert alertTxt={toError} />}
        </div>

        <div className="modal-form__inputs-row">
          <span>Posizione</span>
          <SelectBox
            value={sreenshotType}
            onValueChange={(value: any) => setSreenshotType(value)}
            defaultValue={1}
          >
            <SelectBoxItem value={1} text="Ritaglio Semplice" />
            <SelectBoxItem value={2} text="Ritaglio con Browser" />
            <SelectBoxItem value={3} text="Pagina Intera" />
          </SelectBox>
        </div>
        <div className="modal-form__inputs-row">
          <span>Oggetto:</span>
          <input
            type="text"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            onBlur={() => setSubjectError(validateSubject(subject))}
          />
          {subjectError && <Alert alertTxt={subjectError} />}
        </div>

        <div className="modal-form__inputs-row textarea">
          <span>Messaggio:</span>
          <textarea
            cols={1}
            rows={14}
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>

        <input
          type="hidden"
          name="custom_field"
          value={
            urls.map((src) => {
              return (
                `<img src=${src} alt="Screenshot"
                  style="max-width:600px;"
                />
                <br/><br/>`
              )
            }
            ).join('')
          }
        />

        <div className="modal-form__row">
          <div className="modal-form__files">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#6B6CF9">
              <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
            </svg>
            <span>File in allegato: <strong>{props.attachedFiles}</strong></span>
          </div>
          <input
            type="submit"
            className="cta"
            value="Invia"
            disabled={
              to !== ''
                && subject !== ''
                && toError === ''
                && subjectError === ''
                ? false
                : true
            }
          />
        </div>

      </form>
    </div>
  )
}

export default ModalForm