import { useState } from 'react';

const InputURL = ({
  initialValue,
  onBlur,
  callbackOnKeyUp,
}: {
  initialValue: string;
  onBlur: any;
  callbackOnKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}) => {
  const [value, setValue] = useState(initialValue);

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    callbackOnKeyUp && callbackOnKeyUp(event);
  };

  return (
    <>
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => onBlur(btoa(initialValue), value)}
        onKeyUp={handleOnKeyUp}
      />
    </>
  );
};

export default InputURL;
