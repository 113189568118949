import { useEffect } from 'react';
import Campaign from '../../features/campaigns/Campaign';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  selectOrdersArchive,
  fetchCampaigns,
  selectTxtMongo,
} from '../../features/campaigns/campaignsSlice';
import { setAdvancedFiltersOpen } from '../../features/filters/FiltersSlice';
import AdvancedFilters from '../../features/filters/AdvancedFilters';

const CampaignsHistory = () => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector(selectOrdersArchive);
  const txtMongo = useAppSelector(selectTxtMongo);

  useEffect(() => {
    setTimeout(() => {
      if (txtMongo && txtMongo !== '') {
        dispatch(
          fetchCampaigns({
            limit: 200,
            txtQuery: txtMongo,
            forceFetch: true,
            isArchiveQuery: true,
          }),
        );
      } else {
        dispatch(
          fetchCampaigns({
            limit: 200,
            txtQuery: '___notavalidquery__',
            forceFetch: true,
            isArchiveQuery: true,
          }),
        );
      }
    }, 200);

    dispatch(setAdvancedFiltersOpen(true));
  }, [dispatch, txtMongo]);

  return (
    <div className="dashboard active-campaigns storico">
      <h2>Tutte le campagne</h2>

      <AdvancedFilters deviceEvents={[]} order={''} />

      <span className="campaigns__total">Risultati: {orders.length}</span>

      <div className="campaigns">
        <Campaign orders={orders} />
      </div>
    </div>
  );
};

export default CampaignsHistory;
