import { useAppDispatch } from '../../../app/hooks';
import { setModalOpen } from '../../campaigns/campaignsSlice';

const ModalDiscardScreenshot = (props: any) => {

  const dispatch = useAppDispatch();
  const handleScreenshotDiscard = () => {
    dispatch(setModalOpen());
    props.onScreenshotDiscard();
  };

  return (
    <div className="modal-delete-user modal-discard-screenshot">
      <span className="h2">
        Sei sicuro di voler scartare questo screenshot?
      </span>

      <div className="ctas">
        <div className="cta" onClick={() => dispatch(setModalOpen())}>
          <span>Annulla</span>
        </div>
        <div className="cta cta__delete" onClick={handleScreenshotDiscard}>
          <span>Scarta</span>
        </div>
      </div>
    </div>
  );
};

export default ModalDiscardScreenshot;
