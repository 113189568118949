import * as Realm from "realm-web";

import {
  mongoTestateCollection
} from '../../commons/config/settings';
import { Testata } from './TestateSlice';
import { getPaparazzoCollection } from "../commons/apiUtils";


const {
  BSON: { ObjectId },
} = Realm;


export async function getTestate(): Promise<Array<Testata>> {
  const collection = getPaparazzoCollection(mongoTestateCollection);

  //@ts-ignore
  const result: Array<Testata> = (await collection?.find({})).map((testata) => {
    return {
      ...testata,
      _id: testata._id.toString(),
      id: testata._id.toString(),
    };
  });

  return result;
}

export async function newTestata(testata: Testata): Promise<any> { 
  const collection = getPaparazzoCollection(mongoTestateCollection);

  try {
    const result = await collection?.insertOne(testata);

    return {
      ...testata,
      id: result?.insertedId.toString(),
    };
  } catch (e) {
    //@ts-ignore
    return {error: e.errorCode};
  }
}

export async function removeTestata(id: string): Promise<any> { 
  const collection = getPaparazzoCollection(mongoTestateCollection);

  try {
    const result = await collection?.deleteOne({ _id: new ObjectId(id) });

    return result;
  } catch (e) {
    //@ts-ignore
    return {error: e.errorCode};
  }
}

export async function updateTestata(testataObj: Testata): Promise<Testata> {
  const collection = getPaparazzoCollection(mongoTestateCollection);
  const _testata = {...testataObj};
  delete _testata._id;

  try {
    const result = await collection?.findOneAndUpdate(
      { id: testataObj.id },
      _testata,
      { returnNewDocument: true }
    );

    return result;
  
  } catch (e) {
    throw new Error(`Paparazzo: errore edit testata`);
  }
}