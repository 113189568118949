import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import marcoReducer from '../features/marcoComponent/marcoSlice';
import campaignsReducer from '../features/campaigns/campaignsSlice';
import filtersReducer from '../features/filters/FiltersSlice'
import sidebarReducer from '../features/sidebar/SidebarSlice';
import usersReducer from '../features/users/UsersSlice';
import testateReducer from '../features/testate/TestateSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer, // ------> test reducer
    marco: marcoReducer, // ------> test reducer

    campaigns: campaignsReducer,
    users: usersReducer,
    testate: testateReducer,
    sidebar: sidebarReducer,
    filters: filtersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
