import {
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  TextInput,
  SelectBox,
  SelectBoxItem,
} from '@tremor/react';
import { useEffect, useRef, useState } from 'react';
import { setModalOpen } from '../campaigns/campaignsSlice';
import ModalDeleteUrlTestata from '../campaigns/modals/ModalDeleteUrlTestata';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  fetchTestate,
  selectTestateList,
  optimisticUpdateTestata,
  Testata,
  setTestateFilter,
  editTestata,
  setSorting,
} from './TestateSlice';
import InputURL from './InputURL';
import { isValidUrl, removeHttp } from '../../utils/utils';
import ModaDeleteTestata from '../campaigns/modals/ModaDeleteTestata';

const TableTestate = () => {
  const testateList = useAppSelector(selectTestateList);
  const notEmptyTestate = testateList.length > 0;
  const dispatch = useAppDispatch();
  const [activeTestata, setActiveTestata] = useState<String | null>(null);
  const _url = new URL(window.location.href);
  const _testata = _url.searchParams.get('testata');
  const currentTestata = _testata ? atob(_testata) : "";

  useEffect(() => {
    dispatch(fetchTestate());
    dispatch(setTestateFilter(`${currentTestata}`));
  }, []);

  // Toggle function to display or not child items
  const handleTestataClick = (id: string | null) => {
    setActiveTestata(activeTestata === id ? null : id);
  };

  const handleTestataURLChange =
    (testata: Testata) => (urlId: string, newUrl: string) => {
      if (!testata.baseURL || !isValidUrl(testata.baseURL)) {
        return false;
      }

      const _urls = testata.urls?.map((url) => {
        if (btoa(url) === urlId) return removeHttp(newUrl);
        return url;
      });

      const _uniqueUrls = Array.from(new Set(_urls));

      const _testata = {
        ...testata,
        urls: _uniqueUrls,
      };

      dispatch(editTestata(_testata));
    };

  // Handle select change event
  const handleTestataChange = (
    testataId: string,
    field: string,
    newValue: string | number,
  ) => {
    try {
      const _testata = [...testateList].filter(
        ({ id }) => id === testataId,
      )[0];
      const _newTestata = {
        ..._testata,
      };

      //@ts-ignore
      _newTestata[field] = newValue;

      //@ts-ignore
      dispatch(editTestata(_newTestata));
    } catch (e) {
      console.log('Testata non trovata');
    }
  };

  const handleOnAddUrl = (testataParam: Testata) => {
    //@ts-ignore
    if (testataParam.urls[testataParam.urls?.length - 1] === '') {
      return false;
    }

    const addEmptyUrl = testataParam.urls?.concat(['']);

    const _testata = {
      ...testataParam,
      urls: addEmptyUrl,
    };

    dispatch(optimisticUpdateTestata(_testata));
  };

  const lastInputRef = useRef<any | null>(null);

  return (
    <div className="pzo__table-route campaigns__table users__table testate__table bradius-bshadow bg-transparent-white">
      <div className="pzo__table-filter">
        <TextInput
          placeholder="Filtra testate..."
          onChange={(e) => dispatch(setTestateFilter(e.target.value))}
        />
      </div>
      <Table marginTop="mt-0">
        <TableHead>
          <TableRow>
            <TableHeaderCell>
              <span
                className="table__head"
                onClick={() => dispatch(setSorting('NAME'))}
              >
                Nome
              </span>
            </TableHeaderCell>
            <TableHeaderCell>
              <span>Priorità</span>
            </TableHeaderCell>
            <TableHeaderCell>
              <span
                className="table__head"
                onClick={() => dispatch(setSorting('TYPE'))}
              >
                Tipologia
              </span>
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notEmptyTestate ? (
            testateList.map((testata) => (
              <TableRow key={testata.id}>
                <TableCell>
                  <div
                    className={`testata-title__container  ${
                      activeTestata === testata.id ? 'active' : ''
                    }`}
                  >
                    <div
                      className="testata-title__row"
                      onClick={() => handleTestataClick(testata.id)}
                    >
                      <span className="testata-title__title">
                        {testata.baseURL}
                      </span>
                      <span className="testata-title__toggle">
                        <svg
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#16236A"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="testata-title__childs">
                      {testata.urls?.map((url, index) => {
                        const isLastElement =
                          testata.urls && index === testata.urls.length - 1;

                        const handleKeyUp = (
                          event: React.KeyboardEvent<HTMLInputElement>,
                        ) => {
                          if (isLastElement && event.key === 'Enter') {
                            handleOnAddUrl(testata);
                          }
                        };

                        return (
                          <div key={btoa(url+index)} className="testata-title__child">
                            <InputURL
                              initialValue={url}
                              onBlur={handleTestataURLChange(testata)}
                              callbackOnKeyUp={handleKeyUp}
                            />
                            <span
                              onClick={() =>
                                dispatch(
                                  setModalOpen(
                                    <ModalDeleteUrlTestata
                                      testata={testata}
                                      urlId={btoa(url)}
                                    />,
                                  ),
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm3 10.5a.75.75 0 000-1.5H9a.75.75 0 000 1.5h6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </div>
                        );
                      })}
                      <button
                        className="testata-title__add-url"
                        onClick={() => handleOnAddUrl(testata)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>Aggiungi una nuova URL</span>
                      </button>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="testata-select min-2">
                    <SelectBox
                      value={testata.priority}
                      onValueChange={(value: number) =>
                        handleTestataChange(
                          testata.id,
                          'priority',
                          value,
                        )
                      }
                    >
                      <SelectBoxItem value={1} text="Bassa" />
                      <SelectBoxItem value={2} text="Media" />
                      <SelectBoxItem value={3} text="Alta" />
                    </SelectBox>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="testata-select testata-tipologia-select min-w">
                    <SelectBox
                      value={testata.type}
                      onValueChange={(value: string) =>
                        handleTestataChange(
                          testata.id,
                          'type',
                          value,
                        )
                      }
                    >
                      <SelectBoxItem value={'DISPLAY'} text="Display" />
                      <SelectBoxItem value={'PREROLL'} text="Preroll" />
                    </SelectBox>
                  </div>
                </TableCell>
                <TableCell>
                  <span
                    className="delete-user"
                    onClick={() =>
                      dispatch(
                        setModalOpen(
                          <ModaDeleteTestata testata={testata} />,
                        ),
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#FF632F"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                    Elimina
                  </span>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>Nessuna testata trovata.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableTestate;
