import { useAppSelector } from '../../app/hooks';
import '../styles/index.css';
import '@tremor/react/dist/esm/tremor.css';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { Outlet } from "react-router-dom";
import { selectSidebarState } from '../../features/sidebar/SidebarSlice';
import {
  selectIsModalOpen,
  selectActiveOrder,
  selectModalComponentActive
} from '../../features/campaigns/campaignsSlice';
import Modal from '../../features/campaigns/modals/Modal';
import { useDisableBodyScroll } from '../../utils/utils';
import { Toaster } from 'react-hot-toast';
import { useIsAuthenticated } from 'react-auth-kit'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Root = () => {
  const rootState = useAppSelector(selectSidebarState)
  const isModalOpen = useAppSelector(selectIsModalOpen);
  const activeOrder = useAppSelector(selectActiveOrder);
  const modalComponentActive = useAppSelector(selectModalComponentActive);
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (location.pathname === '/') {
      if (isAuthenticated()) {
        navigate('/dashboard');
      } else {
        navigate('/login');
      }
    }
  }, []);
  
  useDisableBodyScroll(isModalOpen);

  return (
    <>
      {isAuthenticated() &&
          <div
            id="pzo__app"
            className={rootState.isSidebarOpen ? "sidebar--open" : "sidebar--closed"}>
            <Sidebar />
            <div id="pzo__main">
              <Topbar />
              <Outlet />
            </div>
            {
              isModalOpen &&
              <Modal
                order={activeOrder}
                state={isModalOpen}
              >
                {modalComponentActive && modalComponentActive}
              </Modal>
            
            }

            <Toaster
              position="top-center"
              reverseOrder={false}
            />

          </div>
      }

      {!isAuthenticated() &&
        <div id="pzo__app">
          <Outlet />
        </div>
      }
  </>
  );
}

export default Root;