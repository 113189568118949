import { useId, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { SelectBox, SelectBoxItem } from '@tremor/react';
import Alert from '../campaigns/alert/Alert';
import { createUser, selectError, User, editUser } from './UsersSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectUsersList } from './UsersSlice';
import { validateEmails, validateNames } from '../../utils/utils';

const UserForm = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const location = useLocation();
  const usersList = useAppSelector(selectUsersList);
  const error = useAppSelector(selectError);

  // check route
  const isAddUserPage =
    location.pathname === `/admin/utenze/nuovo-utente` ? true : false;

  const getUserById = (
    id: string,
    param: 'name' | 'email' | 'role',
  ): string => {
    const user = usersList.find((user) => user.id.toString() === id);
    const data = param === 'name' || param === 'role' ? 'custom_data' : 'data';
    //@ts-ignore
    return user ? user[data][param] : '';
  };

  // Local user state
  const [userName, setUserName] = useState(
    userId ? getUserById(userId, 'name') : '',
  );
  const [userEmail, setUserEmail] = useState(
    userId ? getUserById(userId, 'email') : '',
  );
  const [userRole, setUseRole] = useState(
    userId ? getUserById(userId, 'role') : 'agente',
  );

  const [userNameError, setUserNameError] = useState('');
  const [userEmailError, setUserEmailError] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const isUserFormValidate4Create =
    userName !== '' &&
    userEmail !== '' &&
    userNameError === '' &&
    userEmailError === ''
      ? true
      : false;

  const isUserFormValidate4Edit =
    userName !== '' &&
    userEmail !== ''

  const handleSave = () => {
    let user: User;
    if (isLoading && error === '') {
      return false;
    }
    if (isAddUserPage) {
      user = {
        id: new Date().getTime(),
        data: {
          email: userEmail,
        },
        custom_data: {
          role: userRole.toLowerCase(),
          name: userName,
        },
      };
      setIsLoading(true);
      isUserFormValidate4Create && dispatch(createUser(user));
    } else {
      user = {
        id: parseInt(`${userId}`),
        custom_data: {
          name: userName,
          role: userRole.toLowerCase(),
        },
        data: {
          email: userEmail,
        },
      };
      setIsLoading(true);
      isUserFormValidate4Edit && dispatch(editUser(user));
    }
  };

  return (
    <>
      <h3 className="user-form__title">
        {isAddUserPage ? 'Crea nuovo utente' : 'Modifica utente'}
      </h3>
      <div className={`user-form__form bg-transparent-white`}>
        <div className="row">
          <label>
            <span>*</span>Nome
          </label>
          <input
            type="text"
            value={userName}
            placeholder={userId && getUserById(userId, 'name')}
            onChange={(e) => setUserName(e.target.value)}
            onBlur={() => setUserNameError(validateNames(userName))}
          />

          {isAddUserPage && userNameError && <Alert alertTxt={userNameError} />}
        </div>
        <div className="row">
          <label>
            <span>*</span>Email
          </label>
          <input
            type="text"
            placeholder={userId && getUserById(userId, 'email')}
            onChange={(e) => setUserEmail(e.target.value)}
            onBlur={() => setUserEmailError(validateEmails(userEmail))}
            value={userEmail}
            disabled={!isAddUserPage && true}
          />

          {isAddUserPage && userEmailError && (
            <Alert alertTxt={userEmailError} />
          )}
        </div>
        <div className="row">
          <label>
            <span>*</span>Ruolo
          </label>
          <SelectBox
            value={userRole}
            onValueChange={(value: string) => setUseRole(value)}
            defaultValue={userRole}
          >
            <SelectBoxItem value={'admin'} text="Admin" />
            <SelectBoxItem value={'agent'} text="Agente" />
          </SelectBox>
        </div>
        <div
          className={`cta ${
            isAddUserPage && !isUserFormValidate4Create ? 'disabled' : ''
          }`}
          onClick={handleSave}
        >
          {isAddUserPage ? 'Salva' : 'Salva impostazioni'}
        </div>
        {error && error !== '' && (
          <div
            className="err--message"
            style={{ color: 'red', marginTop: 20, textAlign: 'center' }}
          >
            <p>{error}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default UserForm;
