import { useAppDispatch } from '../../../app/hooks';
import { Testata, deleteTestata } from '../../testate/TestateSlice';
import { setModalOpen } from '../campaignsSlice';

const ModaDeleteTestata = ({ testata }: { testata: Testata }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="modal-delete-user" style={{ maxWidth: '420px' }}>
      <span className="h2">Sei sicuro di voler eliminare questa testata?</span>

      <div className="user">
        <span className="user__name" style={{ wordBreak: 'break-word' }}>
          {testata.baseURL}
        </span>
      </div>

      <div className="ctas">
        <div className="cta" onClick={() => dispatch(setModalOpen())}>
          <span>Annulla</span>
        </div>
        <div
          className="cta cta__delete"
          onClick={() => {
            dispatch(deleteTestata(testata.id));
          }}
        >
          <span>Elimina</span>
        </div>
      </div>
    </div>
  );
};

export default ModaDeleteTestata;
