import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectIsSelEnabled,
  setModalOpen,
  toggleSelectedForSend,
  selectIsCardSelected,
} from './campaignsSlice';
import {
  mongoEndpoint,
  screenshotBasePath,
} from '../../commons/config/settings';
import ModalSlideshow from './modals/ModalSlideshow';
import Placeholder from '../../commons/images/screenshot_placeholder.png';
import { IDeviceEvent } from './PaparazzoTypes';
import axios from 'axios';
import { useState } from 'react';
import LazyLoad from 'react-lazy-load';
import ModalDiscardScreenshot from './modals/ModalDiscardScreenshot';
import { useAuthUser } from 'react-auth-kit';

const ScreenshotCard = ({ deviceEvent }: { deviceEvent: IDeviceEvent }) => {
  const auth = useAuthUser();
  const user = auth();
  console.log(user?.custom_data.role)

  const dispatch = useAppDispatch();
  const { urlClippedBase, date } = deviceEvent;
  const screenshotUrl = screenshotBasePath + urlClippedBase;
  const key = btoa(`${screenshotUrl}${date}`);
  const isSelectionEnabled = useAppSelector(selectIsSelEnabled);
  const isSelected = useAppSelector((state) =>
    selectIsCardSelected(state, key),
  );
  const handleOnClick = () => {
    dispatch(toggleSelectedForSend(key));
  };

  const [isMarked, setIsMarked] = useState(deviceEvent.isMarked);

  let screenshotSize: string = '';
  if (deviceEvent.nativeEvent?.size || deviceEvent.size) {
    if (deviceEvent.nativeEvent?.isSkin || deviceEvent.isSkin) {
      screenshotSize = 'Skin';
    } else if (deviceEvent.nativeEvent?.isIntro || deviceEvent.isIntro) {
      screenshotSize = 'Intro';
    } else {
      const _sizeArr = deviceEvent.nativeEvent?.size || deviceEvent.size || [];
      screenshotSize = `${_sizeArr.join('x')}`;
    }
  }

  const handleMarkAsBroken = async (key: string) => {
    const result = await axios(`${mongoEndpoint}/mark_event?key=${key}`);

    if (result.data.modifiedCount && result.data.modifiedCount === 1) {
      setIsMarked(true);
    }
  };

  return (
    <div
      data-order={deviceEvent.order}
      data-key={deviceEvent.key}
      style={isMarked ? { opacity: '50%' } : {}}
      className={`screenshot__card screenshot ${
        isSelected && 'screenshot__card--selected'
      }`}
    >
      <div className="screenshot__image">
        <figure
          onClick={() =>
            urlClippedBase &&
            !isSelectionEnabled &&
            dispatch(setModalOpen(<ModalSlideshow deviceEvent={deviceEvent} />))
          }
        >
          <LazyLoad height={170}>
            <img
              src={
                urlClippedBase || urlClippedBase !== undefined
                  ? screenshotUrl
                  : Placeholder
              }
              alt="Screenshot"
            />
          </LazyLoad>
          {urlClippedBase && (
            <div className="screenshot__zoom">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#6B6CF9"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6"
                />
              </svg>
            </div>
          )}
          {isSelectionEnabled && urlClippedBase && (
            <input
              type="checkbox"
              className="screenshot__checkbox"
              onClick={() => handleOnClick()}
              readOnly={true}
              checked={isSelected ? true : false}
            ></input>
          )}
        </figure>
      </div>
      <div className="screenshots__infos">
        <div className="screenshot__head">
          <span>{deviceEvent.testata}</span>
          {deviceEvent.device === 'DESKTOP' && (
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#16236A"
              opacity={0.5}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
              />
            </svg>
          )}
          {deviceEvent.device === 'TABLET' && (
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#16236A"
              opacity={0.5}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5h3m-6.75 2.25h10.5a2.25 2.25 0 002.25-2.25v-15a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 4.5v15a2.25 2.25 0 002.25 2.25z"
              />
            </svg>
          )}
          {deviceEvent.device === 'MOBILE' && (
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="#16236A"
              opacity={0.5}
              className="mobile-svg-icon"
            >
              <path d="M8 16.25a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75z" />
              <path
                fillRule="evenodd"
                d="M4 4a3 3 0 013-3h6a3 3 0 013 3v12a3 3 0 01-3 3H7a3 3 0 01-3-3V4zm4-1.5v.75c0 .414.336.75.75.75h2.5a.75.75 0 00.75-.75V2.5h1A1.5 1.5 0 0114.5 4v12a1.5 1.5 0 01-1.5 1.5H7A1.5 1.5 0 015.5 16V4A1.5 1.5 0 017 2.5h1z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
        <div className="screenshot__info align-flex-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#16236A"
            opacity={0.5}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
            />
          </svg>
          <div>
            <span>{deviceEvent.placement}</span>
            <span className="screenshot__size">{screenshotSize}</span>
          </div>
        </div>
        <div className="screenshot__info">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#16236A"
            opacity={0.5}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>
            {`${new Date(deviceEvent.time).toLocaleDateString()} 
            ${new Date(deviceEvent.time).toLocaleTimeString()}`}
          </span>
        </div>
        {deviceEvent.key && user?.custom_data.role === 'admin' && (
          <div className="screenshot__info">
            <span
              className="screenshot__discard"
              style={{
                cursor: `${!isMarked ? 'pointer' : 'auto'}`,
                pointerEvents: `${
                  !isMarked && !isSelectionEnabled ? 'all' : 'none'
                }`,
                opacity: `${!isMarked && !isSelectionEnabled ? '1' : '0'}`,
              }}
              onClick={() =>
                !isMarked &&
                !isSelectionEnabled &&
                // handleMarkAsBroken(deviceEvent.key)
                dispatch(
                  setModalOpen(
                    <ModalDiscardScreenshot
                      onScreenshotDiscard={handleMarkAsBroken.bind(
                        null,
                        deviceEvent.key,
                      )}
                    />,
                  ),
                )
              }
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </span>
              Scarta
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScreenshotCard;
