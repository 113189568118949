/*
Per la logica usata nei selectors si veda
https://redux.js.org/usage/deriving-data-selectors
e
https://react-redux.js.org/api/hooks
*/

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import {
  removeTestata,
  getTestate,
  newTestata,
  updateTestata,
} from './TestateAPI';

import { isValidUrl } from '../../utils/utils';

export interface Testata {
  _id?: any;
  id: string;
  baseURL: string;
  priority?: number;
  type?: 'DISPLAY' | 'PREROLL';
  urls?: Array<string>;
}

export interface TestataState {
  list: Array<Testata>;
  status: string;
  error: string;
  sorting: string;
  sortingOrder: string;
  testataFilter: string;
}

const initialState: TestataState = {
  list: [],
  status: 'idle',
  sorting: 'BASE_URL',
  error: '',
  sortingOrder: 'ASC',
  testataFilter: '',
};

export const fetchTestate = createAsyncThunk(
  'testate/fetchTestate',
  async () => {
    const response = await getTestate();
    return response;
  },
);

export const deleteTestata = createAsyncThunk(
  'testate/deleteTestata',
  async (id: string) => {
    try {
      const response = await removeTestata(id);

      return response;
    } catch (e) {
      throw new Error('Error creating user');
    }
  },
);

export const editTestata = createAsyncThunk(
  'testate/editTestata',
  async (testataObj: Testata, thunkAPI) => {
    try {
      thunkAPI.dispatch(optimisticUpdateTestata(testataObj));
      if (isValidUrl(testataObj.baseURL)) {
        const response = await updateTestata(testataObj);
        return response;
      }

      return false;
    } catch (e) {
      throw new Error('Error editing testata');
    }
  },
);

export const createTestata = createAsyncThunk(
  'testate/createTestata',
  async (testataObj: Testata, thunkAPI) => {
    try {
      if (isValidUrl(testataObj.baseURL)) {
        const response = await newTestata(testataObj);
        return response;
      }

      return false;
    } catch (e) {
      throw new Error('Error editing testata');
    }
  },
);

export const testateSlice = createSlice({
  name: 'testate',
  initialState,
  reducers: {
    optimisticUpdateTestata: (state, action: PayloadAction<Testata>) => {
      const _newList = state.list.map((testata) => {
        if (testata.id === action.payload.id) {
          testata = action.payload;
        }

        return testata;
      });

      state.list = _newList;
    },
    setSorting: (state, action: PayloadAction<string>) => {
      if (state.sorting === action.payload) {
        state.sortingOrder = state.sortingOrder === 'ASC' ? 'DESC' : 'ASC';
      } else {
        state.sorting = action.payload;
      }
    },
    setSortingOrder: (state, action: PayloadAction<string>) => {
      state.sortingOrder = action.payload;
    },
    setTestateFilter: (state, action: PayloadAction<string>) => {
      state.testataFilter = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTestate.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(fetchTestate.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(createTestata.pending, (state) => {
        state.error = '';
        state.status = 'loading';
      })
      .addCase(createTestata.fulfilled, (state, action) => {
        state.status = 'idle';
        //@ts-ignore
        if (action.payload.error) {
          console.log(action.payload.error);
        } else {
          window.location.href = `/admin/testate?testata=${btoa(
            action.payload.baseURL,
          )}`;
        }
      })
      .addCase(createTestata.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(editTestata.pending, (state, action) => {
        state.error = '';
        state.status = 'loading';
      })
      .addCase(editTestata.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(editTestata.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(deleteTestata.pending, (state) => {
        state.error = '';
        state.status = 'loading';
      })
      .addCase(deleteTestata.fulfilled, (state, action) => {
        state.status = 'idle';
        window.location.href = '/admin/testate';
      })
      .addCase(deleteTestata.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  optimisticUpdateTestata,
  setSorting,
  setSortingOrder,
  setTestateFilter,
} = testateSlice.actions;

const selectTestateStateList = (state: RootState) => state.testate.list;

export const selectSorting = (state: RootState) => state.testate.sorting;
export const selectSortingOrder = (state: RootState) =>
  state.testate.sortingOrder;
export const selectTestataFilter = (state: RootState) =>
  state.testate.testataFilter;

export const selectTestateList = createSelector(
  [
    selectTestateStateList,
    selectSorting,
    selectSortingOrder,
    selectTestataFilter,
  ],
  (usersList, sorting, order, testataFilter) =>
    [...usersList]
      .filter((testata: Testata) => testata.baseURL.includes(testataFilter))
      .sort((a, b) => {
        switch (sorting) {
          case 'NAME':
            return order === 'ASC'
              ? a.baseURL.localeCompare(b.baseURL)
              : b.baseURL.localeCompare(a.baseURL);
          case 'TYPE':
            if (a.type && b.type) {
              return order === 'ASC'
                ? a.type.localeCompare(b.type)
                : b.type.localeCompare(a.type);
            }
            return 0;
          default:
            return 1;
        }
      }),
);

const selectTestataURL = (state: RootState, baseUrl: string) => baseUrl;
export const selectStatus = (state: RootState) => state.testate.status;
export const selectError = (state: RootState) => state.testate.error;

export const selectTestata = createSelector(
  [selectTestateList, selectTestataURL],
  (testateList, testataURL) =>
    testateList.filter((testata) => testata.baseURL === testataURL),
);

export default testateSlice.reducer;
