import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { toggleFiltersOpen, selectIsFiltersOpen } from './FiltersSlice';
import { useEffect, useState } from 'react';
import { IDeviceEvent, ILineItem } from '../campaigns/PaparazzoTypes';
import { setEventFilter, resetEventFilter, selectActiveOrder } from '../campaigns/campaignsSlice';
import {
  MultiSelectBox,
  MultiSelectBoxItem,
  SelectBox,
  SelectBoxItem,
  DateRangePicker,
  DateRangePickerValue
} from "@tremor/react";
import './Filters.css';

const Filters = () => {
  const dispatch = useAppDispatch();
  const orderDetail = useAppSelector(selectActiveOrder);
  const isFiltersOpen = useAppSelector(selectIsFiltersOpen);
  const eventFilters = useAppSelector((rootState) => rootState.campaigns.eventFilter);
  const {deviceEvents, lineItems, order}  = orderDetail;
  const uniqueTestate = deviceEvents ?
    deviceEvents.filter((item: IDeviceEvent, index: number, self: any) => index === self.findIndex((t: any) => t.testata === item.testata)) :
    [];
  const uniquePlacement = deviceEvents ? 
    deviceEvents.filter((item: IDeviceEvent, index: number, self: any) => index === self.findIndex((t: any) => t.placement === item.placement)) :
    [];
  const uniqueLineItems = lineItems ? lineItems.map((li: any) => li.lineItem) : [];

  // Local state
  const [clientName, setClientName] = useState('');
  const [filterLineItems, setFilterLineItems] = useState([...eventFilters.lineItems || []]);
  const [testate, setTestate] = useState([]);
  const [placements, setPlacements] = useState([]);
  const [device, setDevice] = useState('');
  const [selectedDates, setSelectedDates] = useState<DateRangePickerValue>([]);

  const getFilteredObject = () => {
    return {
      clientName,
      device,
      placements,
      testate,
      lineItems: filterLineItems,
      selectedDates: [
        selectedDates[0] && new Date(selectedDates[0]).getTime(),
        selectedDates[1] && new Date(selectedDates[1]).getTime()
      ],
    };
  }


  const handleLineItemSelection = (options: any) => {
    setFilterLineItems(options);
  }

  const handleTestataSelection = (options: any) => {
    setTestate(options);
  }

  const handlePlacementSelection = (options: any) => {
    setPlacements(options);
  }

  const handleDeleteFilters = () => {
    setClientName('');
    setFilterLineItems([]);
    setTestate([]);
    setPlacements([]);
    setDevice('');
    setSelectedDates([]);
    dispatch(resetEventFilter());
  }

  useEffect(() => {
    dispatch(setEventFilter(getFilteredObject()));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={isFiltersOpen ? 'filters filters--open' : 'filters'}>

      <div className='filters__toggle' onClick={() => dispatch(toggleFiltersOpen())}>

        <div className='filters__label'>
          <span className='filters__icon'>
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#16236A">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
            </svg>
          </span>
          <span className='filters__title'>
            {
              isFiltersOpen ? 'Nascondi filtri' : 'Mostra filtri'
            }
          </span>
        </div>

        <span className='filters__arrow'>
          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#16236A">
            <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
          </svg>
        </span>
      </div>

      <div className='filters__dropdown filter'>

        <div className='filter__row'>

          <div className='filter__input multi-select'>
            <label>Line item</label>

            <MultiSelectBox
              value={filterLineItems}
              onValueChange={handleLineItemSelection}
              placeholder="Seleziona..."
              icon={undefined}
              maxWidth="max-w-none"
              marginTop="mt-0"
            >
              {
                
                uniqueLineItems && uniqueLineItems.map((item: IDeviceEvent, idx: number) => {
                  let itemReplaced = item.toString().replace(order.substring(0, order.length - 5), '');
                  return (
                    <MultiSelectBoxItem
                      key={idx}
                      text={`${itemReplaced}`}
                      value={item}
                    />
                  )
                })
                
              }
            </MultiSelectBox>

          </div>

          <div className='filter__input date-picker'>
            <label>Seleziona data</label>
            <DateRangePicker
              defaultValue={selectedDates}
              value={selectedDates}
              onValueChange={setSelectedDates}
              placeholder=""
              //@ts-ignore
              enableRelativeDates={true}
              enableYearPagination={false}
              defaultStartDate={null}
              defaultEndDate={null}
              defaultRelativeFilterOption={null}
              minDate={null}
              maxDate={null}
              color="blue"
              maxWidth="max-w-none"
              marginTop="mt-0"
            />
          </div>

        </div>

        <div className='filter__row'>

          <div className='filter__input multi-select'>
            <label>Testata</label>
            <MultiSelectBox
              value={testate}
              onValueChange={handleTestataSelection}
              placeholder="Seleziona..."
              icon={undefined}
              maxWidth="max-w-none"
              marginTop="mt-0"
            >
              {
                uniqueTestate?.map((item: IDeviceEvent, idx: number) => {
                  return (
                    <MultiSelectBoxItem
                      key={idx}
                      text={`${item.testata}`}
                      value={item.testata}
                    />
                  )
                })
              }
            </MultiSelectBox>
          </div>

          <div className='filter__input multi-select'>
            <label>Posizione</label>
            <MultiSelectBox
              value={placements}
              onValueChange={handlePlacementSelection}
              placeholder="Seleziona..."
              icon={undefined}
              maxWidth="max-w-none"
              marginTop="mt-0"
            >
              {
                uniquePlacement && uniquePlacement.map((item: IDeviceEvent, idx: number) => {
                  return (
                    <MultiSelectBoxItem
                      key={idx}
                      text={`${item.placement}`}
                      value={item.placement}
                    />
                  )
                })
                
              }
            </MultiSelectBox>
          </div>

          <div className='filter__input select'>
            <label>Device</label>
            <SelectBox
              value={device}
              onValueChange={(value: string) => setDevice(value)}
              placeholder={'Seleziona...'}
            >
              <SelectBoxItem value={''} text="Tutti" />
              <SelectBoxItem value={'DESKTOP'} text="Desktop" />
              <SelectBoxItem value={'TABLET'} text="Tablet" />
              <SelectBoxItem value={'MOBILE'} text="Mobile" />
            </SelectBox>

          </div>
        </div>

        <div className='filter__row'>
          <button
            onClick={handleDeleteFilters}
            className='cta cta--outlined'>Elimina filtri
          </button>

          <button
            onClick={() => dispatch(setEventFilter(getFilteredObject()))}
            className='cta'>Applica filtri
          </button>

        </div>
      </div>

    </div>

  )
}

export default Filters