import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ManzoniLogo from '../../commons/images/manzoni_logo.png'
import '../styles/Topbar.css'
import { setSidebarOpen, selectSidebarState } from '../../features/sidebar/SidebarSlice';

const Topbar = () => {
    const rootState = useAppSelector(selectSidebarState)
    const dispatch = useAppDispatch();

    return (

        <div id='pzo__topbar' className='pzo__topbar topbar'>

            <div className={rootState.isSidebarOpen ? 'topbar__close-sidebar' : 'topbar__close-sidebar sidebar-closed'}
                onClick={() => dispatch(setSidebarOpen())}>
                {
                    rootState.isSidebarOpen
                        ?
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#16236A" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#16236A" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                        </svg>
                }
            </div>

            <div className='topbar__customer-logo'>
                <img src={ManzoniLogo} alt="Manzoni Advertising" />
            </div>
            {/* {location.pathname === '/campagne' && <Searchbar />} */}
        </div>
    )
}

export default Topbar